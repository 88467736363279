import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useGeneratorQuery, useEventID, RINGBA_STORAGE_KEYS, useRingba } from "wecall-config-lib";
import { Timer } from "../App";
// import logo from "../assets/logo.png";
// import star from "../assets/star.png";
import { Link, Outlet, Route, Routes } from "react-router-dom";
// import arrow from "../assets/arrow.svg";
// import testimonial from "../assets/trustpilot-logo.png";
// import user1 from "../assets/user-1.jpeg";
// import user2 from "../assets/user-2.jpeg";
// import user3 from "../assets/user-3.jpeg";
// import user4 from "../assets/user-4.jpeg";
import InitialScripts from "../script/initialScripts";
// import FloatingCard from "../floating-card";
// import gasImage from "../assets/gas.webp";
// import groceryImage from "../assets/groceries.webp";
// import rentImage from "../assets/rent.webp";
// import shoppingImage from "../assets/shopping.webp";
// import trustseal from "../assets/trust-seal.png";

const FloatingCard = React.lazy(() => import("../floating-card"));

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

const ROUTES = {
  congrats: "congrats",
  income: "income",
  medicareMedicaid: "medicare-medicaid",
};

export function Lander3Congrats({ number, headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();
  const generatorQuery = useGeneratorQuery();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.congrats, true);
  }, []);

  return (
    <>
      <div className="question-item">
        <div className="congrats">
          <h2>{headline}</h2>
          <p>Space is limited. Tap the button below to call and claim.</p>
          <a
            onClick={triggerContactEvent}
            href={"tel:" + number}
            className="call_now"
          >
            {number}
          </a>
          <p>
            This program has extremely limited capacity, so call us in the next{" "}
            <Timer /> to claim your subsidy before it's given to another
            applicant in line.
          </p>
          <img loading="lazy" src={'/assets/trust-seal.png'} alt=""></img>
        </div>
      </div>
    </>
  );
}

export function Lander3MedicareMedicaid({ headline }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const navigate = useNavigate();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.medicareMedicaid]: value });
  };

  const externalRedirectLink = window.domain_settings.medicareMedicAidYesLink;

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.medicareMedicaid, true);
  }, []);
  return (
    <>
      <div className="question-wrapper">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <div className="question-item">
          <div className="question">
            <h2>Are you on Medicare or Medicaid?</h2>

            <Link
              onClick={() => setValues("yes")}
              id="medicare_medicaid_yes"
              to={{ pathname: "../congrats", search: generatorQuery.get() }}
            >
              Yes
            </Link>

            <Link
              onClick={() => setValues("no")}
              id="medicare_medicaid_no"
              to={{ pathname: "../congrats", search: generatorQuery.get() }}
            >
              No
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export function Lander3Income({ headline }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    // storeRgbaData(RINGBA_STORAGE_KEYS.income, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.income]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="question-wrapper">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <div className="question-item">
          <div className="question">
            <h2>Do you make less than $50k/Yr?</h2>
            <Link
              onClick={() => setValues("- - 50")}
              to={{
                pathname: "../medicare-medicaid",
                search: generatorQuery.get(),
              }}
            >
              Yes
            </Link>
            <Link
              onClick={() => setValues("50 - +")}
              to={{
                pathname: "../medicare-medicaid",
                search: generatorQuery.get(),
              }}
            >
              No
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export function Lander3CTA({ headline, subtitle, paragraph }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    // generatorQuery.set(window.location.search);
    sessionStorage.setItem(routes.lander, true);
  }, []);
  return (
    <>
      <div className="hero-container">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <div className="sub-hero-section">
          <div className="hero-section-wrapper">
            <div className="hero-section-wrapper-content">
              <h2>{subtitle}</h2>
              <p>{paragraph}</p>
            </div>
            {
              //  <div className="hero-sectiom-image">
              //  <img loading="lazy" src={heroimage}></img>
              //  </div>
            }
            <div className="cta">
              <Link
                id="cta-click"
                to={{ pathname: "income", search: generatorQuery.get() }}
              >
                I Want Tax Credit Subsidy <img loading="lazy" src={'/assets/arrow.svg'} alt=""></img>
              </Link>
              <span>37 Available Slots Left, Act Now!</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function Lander3({ setNumber }) {
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img loading="lazy" src={'/assets/logo.png'}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          {
            //
            // <div className="logo-strip-section">
            //  <div className="container">
            //    <div className="row">
            //      <div className="logo-strip-headline">
            //        <h1>MENTIONED ON</h1>
            //      </div>
            //      <div className="logo-strip">
            //        <div className="logoitem">
            //          <img loading="lazy" src={newslogo1}></img>
            //          <img loading="lazy" src={newslogo2}></img>
            //          <img loading="lazy" src={newslogo3}></img>
            //          <img loading="lazy" src={newslogo4}></img>
            //          <img loading="lazy" src={newslogo5}></img>
            //          <img loading="lazy" src={newslogo6}></img>
            //        </div>
            //      </div>
            //    </div>
            //  </div>
            // </div>
            //
          }
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Check for the New Subsidy for Minimum Wage Americans</h1>
                  <h2>
                    Claim up to $6400 Advanced Premium Tax Credit to pay for your health imsurance premium.
                  </h2>
                  <h2>Anything left over can be used:</h2>
                  <div className="cta-holder">
                    <Link to={"income"} id="cta-click">
                      I Want Tax Credit Subsidy <img loading="lazy" src={'/assets/arrow.svg'} alt=""></img>
                    </Link>
                    <span>37 Available Slots Left, Act Now!</span>
                  </div>
                </div>
                <div className="item-benefits">
                  <ul>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img loading="lazy" src={'/assets/groceries.webp'}></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Groceries</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img loading="lazy" src={'/assets/rent.webp'}></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Rent</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img loading="lazy" src={'/assets/gas.webp'}></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Gas</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img loading="lazy" src={'/assets/shopping.webp'}></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Shopping</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>
                      © Copyright {window.domain_settings.websiteTitle} 2023
                    </p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<></>}>
        <FloatingCard />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
