import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
// import logo from "../assets/logo.png";
//import newslogo1 from "../assets/news-logo01.png";
//import newslogo2 from "../assets/news-logo02.png";
//import newslogo3 from "../assets/news-logo03.png";
//import newslogo4 from "../assets/news-logo04.png";
//import newslogo5 from "../assets/news-logo05.png";
//import newslogo6 from "../assets/news-logo06.png";
// import star from "../assets/star.png";
import { Link, Outlet, Route, Routes } from "react-router-dom";
// import arrow from "../assets/arrow.svg";
// import testimonial from "../assets/trustpilot-logo.png";
// import user1 from "../assets/user-1.jpeg";
// import user2 from "../assets/user-2.jpeg";
// import user3 from "../assets/user-3.jpeg";
// import user4 from "../assets/user-4.jpeg";
// import InitialScripts from "../script/initialScripts";
// import FloatingCard from "../floating-card";
// import gasImage from "../assets/gas.webp";
// import groceryImage from "../assets/groceries.webp";
// import rentImage from "../assets/rent.webp";
// import shoppingImage from "../assets/shopping.webp";
import QuizInitialScripts from "../script/quizInitialScript";
import { useLocation } from "react-router-dom";
import { useInitRingba } from "wecall-config-lib";
import { ROUTES } from "../App";

const FloatingCard = React.lazy(() => import("../floating-card"));

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

function MobileNumberLoader({ setNumber }) {
  const testRingba = window.domain_settings.rinbaUser;

  let ringba = testRingba;
  const generator = ringba.user;
  const { number } = useInitRingba({
    ringbaKey: {
      key: testRingba.key,
      number: testRingba.number,
      user: testRingba.user,
    },
  });

  useEffect(() => {
    setNumber(number);
  }, [number]);
  return <></>;
}

export default function QuizLander({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  const location = useLocation();
  const handleButtonClick = () => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <>
      <QuizInitialScripts setNumber={setNumber} />

      {location.pathname.includes(ROUTES.income) ||
      location.pathname.includes(ROUTES.medicareMedicaid) ||
      location.pathname.includes(ROUTES.congrats) ||
      location.pathname.includes(ROUTES.healthInsurance) ||
      location.pathname.includes(ROUTES.phoneNumber)  ? (
        <MobileNumberLoader setNumber={setNumber} />
      ) : undefined}

      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img loading="lazy" src={"/assets/logo.png"}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          {
            //
            // <div className="logo-strip-section">
            //  <div className="container">
            //    <div className="row">
            //      <div className="logo-strip-headline">
            //        <h1>MENTIONED ON</h1>
            //      </div>
            //      <div className="logo-strip">
            //        <div className="logoitem">
            //          <img loading="lazy" src={newslogo1}></img>
            //          <img loading="lazy" src={newslogo2}></img>
            //          <img loading="lazy" src={newslogo3}></img>
            //          <img loading="lazy" src={newslogo4}></img>
            //          <img loading="lazy" src={newslogo5}></img>
            //          <img loading="lazy" src={newslogo6}></img>
            //        </div>
            //      </div>
            //    </div>
            //  </div>
            // </div>
            //
          }
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Check for the New Subsidy for Minimum Wage Americans</h1>
                  <h2>
                    Claim up to $6400 SUBSIDY to pay for all your health care
                    needs.
                  </h2>
                  <h2>Anything left over can be used:</h2>
                  <div className="cta-holder">
                    <Link to={"income"} id="cta-click" onClick={handleButtonClick}>
                      I Want My Card{" "}
                      <img
                        loading="lazy"
                        src={"/assets/arrow.svg"}
                        alt=""
                      ></img>
                    </Link>
                    <span>37 Available Slots Left, Act Now!</span>
                  </div>
                </div>
                <div className="item-benefits">
                  <ul>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img
                            loading="lazy"
                            src={"/assets/groceries.webp"}
                          ></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Groceries</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img loading="lazy" src={"/assets/rent.webp"}></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Rent</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img loading="lazy" src={"/assets/gas.webp"}></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Gas</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitImage">
                          <img
                            loading="lazy"
                            src={"/assets/shopping.webp"}
                          ></img>
                        </div>
                        <div className="benefitHeadline">
                          <p>Shopping</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Try Yourself Today!</h1>
                  <img
                    loading="lazy"
                    src={"/assets/trustpilot-logo.png"}
                    alt=""
                  />
                </div>
                <div className="testimonial-content-holder">
                  <p>
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </p>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img loading="lazy" src={"/assets/user-1.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img
                              loading="lazy"
                              src={"/assets/star.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Floyd Miles</h3>
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img loading="lazy" src={"/assets/user-2.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img
                              loading="lazy"
                              src={"/assets/star.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Michael Anderson</h3>
                        <p>
                          Just in time when i need it the most! I can't thank US
                          Support enough for their prompt assistance. Their
                          team's commitment to me is truly commendable
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img loading="lazy" src={"/assets/user-4.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img
                              loading="lazy"
                              src={"/assets/star.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Olivia Martinez</h3>
                        <p>
                          I was in a financial bind, and US Support came to the
                          rescue. Their team's dedication and service to people
                          are top-notch, and I couldn't be happier with their
                          help.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img loading="lazy" src={"/assets/user-3.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img
                              loading="lazy"
                              src={"/assets/star.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>David Wilson</h3>
                        <p>
                          I was amazed by how quickly US Support acted to help
                          me as a minimum wage earner. Their dedication is clear
                          and their service is outstanding. Highly recommend
                          them!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img loading="lazy" src={"/assets/user-3.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img
                              loading="lazy"
                              src={"/assets/star.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Sarah Thompson</h3>
                        <p>
                          In a financial pinch, US Support was a lifesaver.
                          Their team's dedication and top-notch service made a
                          huge difference. Extremely grateful for their help!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img loading="lazy" src={"/assets/user-3.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img
                              loading="lazy"
                              src={"/assets/star.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Emily Rodriguez</h3>
                        <p>
                          I was amazed by how quickly US Support acted to help
                          me as a minimum wage earner. Their dedication is clear
                          and their service is outstanding. Highly recommend
                          them!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>
                      © Copyright {window.domain_settings.websiteTitle} 2023
                    </p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<></>}>
        <FloatingCard />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
